.assistants-container {
  display: flex;
  flex: 1;
  background-color: #15161a;
  border-radius: 20px;
  margin-right: 15px;
  border: 0.5px solid #363638;
  height: 100%;
}

.create-assistant-container{
  height: 100%;
  width: 38%;
  border-right: 0.5px solid #363638;
  margin-left: 0px;
  text-align: center
}

.button-assistant{
  width: 85%;
  margin-top: 20px;
  height: 40px;
  background-color: #212328;
  border: 0.5px solid #363638;
  border-radius: 10px;
  color: #b0b0b0;
  font-size: 16px;
  font-weight: 600;
}

.create-assistant-button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.create-assistant-button:hover {
  background-color: #555;
}

.no-assistants {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.no-assistants-icon {
  width: 50px;
  height: 50px;
  
  background-size: contain;
  margin-bottom: 20px;
}

.no-assistants h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.no-assistants p {
  font-size: 16px;
  color: #aaa;
}

.squads-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #363638;
}