.credits-main-heading{
    font-size:20px;
    color:white;
    font-weight:bold;
}
.credit-count{
    font-weight:40px;
    color:white;
    font-weight:bold;
}

.credits-card-container{
    display: flex;
    flex-wrap:wrap;
}