.bc-main-heading{
    font-size:25px;
    font-family: "Roboto";
    font-weight: bold;
    color:white;
}
.business-container-header{
    display: flex;
    justify-content: space-between;
    border-bottom:1px solid 
}