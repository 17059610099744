.template-card{
    max-width: 600px;
    background-color: #0d0c0c;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    width:45%;
    margin:10px;
    padding-top:20px;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:20px;
}

.template-container2{
    padding:20px;
}


.template-container{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    padding:20px;
}
.template-heading{
    font-weight:bold;
    font-size:18px;
}
.template-heading-container{
    display: flex;
    justify-content: space-between;

}
.template-details-span-element{
    font-weight: bold;
    color:white;
}
.template-card p{
    margin-top:7px;
    margin-bottom:7px;
    font-size:14px;
}
.domain-container{
    display: flex;
    flex-direction: column;
    padding:10px;
    text-align: center;
}
.domain-item{
    display: flex;
  align-items: center;
  background-color: #2f3756;
  margin-top:5px;
  margin-bottom:5px;
  border-radius: 10px;
  height: 40px;
  padding: 0px 10px;
  width:90%;
  text-align: center;
}
.domain-item.active {
    background-color: #4b5a89; /* A different color for active state */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-weight: bold;
  }