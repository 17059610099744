.performance-card{
    border:2px solid white;
    border-radius:8px;
    padding:5px;
    background-color: black;
    text-align: left;
    margin:10px;
    width:25%;
}
.pc-heading{
    font-size:20px;
    font-weight:bold;
    font-family: "Roboto";
    color:white;
}
.pc-desc{
    color:white;
}
.pc-stats{
    font-weight:bold;
    color:white;
    font-size:25px;
}
.green-dot{
    background-color: green;
    padding:2px;
    border-radius: 100%;
    height:10px;
    width:10px;
}
.custom-date-range-picker {
    display: flex;
    align-items: center;
    max-width: 300px;
    color:black;
  }

  
  .custom-date-range-picker input {
    padding: 5px;
    margin-bottom: 10px;
    color:black;
    outline:none;
    border:none;
    width:120px;
    margin-left:5px;
    margin-right:5px;
  }
  