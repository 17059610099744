.assistants-container {
    display: flex;
    flex-grow: 1;
    background-color: #15161a;
    border-radius: 20px;
    margin-right: 15px;
    border: 0.5px solid #363638;
}
  
.create-assistant-container {
    width: 30%;
    border-right: 0.5px solid #363638;
    margin-left: 0px;
    text-align: center;
    overflow: auto;
}
.side-container{
  height: 100%;
  width: 75%;
  overflow: auto;
}
.button-assistant{
    width: 85%;
    margin-top: 20px;
    height: 40px;
    background-color: #212328;
    border: 0.5px solid #363638;
    border-radius: 10px;
    color: #b0b0b0;
    font-size: 16px;
    font-weight: 600;
}

.create-assistant-button {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}
  
.create-assistant-button:hover {
    background-color: #555;
}
  
.no-assistants {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
}
  
.no-assistants-icon {
    width: 50px;
    height: 50px;
    
    background-size: contain;
    margin-bottom: 20px;
}
  
.no-assistants h2 {
    font-size: 18px;
    margin-bottom: 10px;
}
  
.no-assistants p {
    font-size: 14px;
    color: #aaa;
}

.createbutton-container{
  border-bottom: 0.5px solid #363638;
  padding-bottom: 20px;
}

.newAssistant-container{
  display: flex;
  align-items: center;
  background-color: #2f3756;
  margin: 20px;
  border-radius: 10px;
  height: 60px;
  padding: 0px 10px;
}

.assistant-name{
    font-size: 14px;
}

.copy-link{
    color: #767683;
    text-align: left;
    font-size: 12px;
    margin-left: 5px;
}