.admin-sidebar-item,
.admin-sidebar-subitem {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.admin-sidebar-item:hover {
  background-color: #15161a;
  border: 0.5px solid #363638;
  border-radius: 8px;
  color: #ffffff;
}

.admin-sidebar-item span {
  flex-grow: 1;
}
.admin-sidebar-item span,
.admin-sidebar-subitem span {
  margin-left: 10px;
}

.mask-tab{
  display:none;
}