/* .behavior-option.active{
    background-color: #2c2c2c;
  color: #00aaff;
} */
.behavior-option.active{
    background-color: lightorange; /* Warm orange background */
  color: #1c1c1c; /* Dark text */
  border-left: 8px solid #16a085; /* Teal left border for accent */
}
.selected-2{
  border-left:5px solid aqua;
}