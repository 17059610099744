@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  *{
    font-family: "Roboto";
  }
  
  .logo {
    
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .img-logo{
    width: 50px;
    padding-right: 10px;
  }
  
  .logo-name{
    font-family: "Roboto";
    font-size: 24px;
    color: #c1c0c0;

    
  }
  .header-buttons {
    display: flex;
    align-items: center;
  }
  
  .header-button {
    display: flex;
    align-items: center;
    background-color: #333333;
    border: none;
    color: #c1c0c0;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .header-button .icon {
    margin-right: 8px;
    color: #ffffff;
  }
  
  .profile-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #444444;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .profile-icon .icon {
    color: #ffffff;
    font-size: 20px;
  }