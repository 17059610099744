.dashboard-container {
    color: #b0b0b0;
    padding: 20px;
    margin: auto;
    font-family: Arial, sans-serif;
    overflow: auto;
  }
  
  .header-statusbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard-heading{
    color: aliceblue;
    font-size: 20px;
  }
  
  .id-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    
  }
  
  .id {
    margin-right: 10px;
    background-color: #080808;
    padding: 10px 15px;
    border: 0.5px solid #363638;
    border-radius: 10px;
  }

  .talk-button{
    background-color: #2c3561;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .copy-button {
    background-color: #3a3a3a;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .metrics {
    width: 100%;
    margin-left: 10px;
    display: flex;
    background-color: #080808;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
  }
  
  .metric {
    flex: 1;
    margin: 0 10px;
  }

  .metric-scale{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
  }
  
  .label {
    display: block;
    margin: 0px;
    font-size: 15px;
  }
  
  .value {
    display: block;
    font-size: 15px;
  }
  
  .bar {
    height: 10px;
    border-radius: 5px;
  }
  
  .cost-bar {
    background-color: #00ff00;
  }
  
  .latency-bar {
    background-color: #ff00ff;
  }
  
  .tabs {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    
  }

  .tabs-container{
    background-color: #080808;
    padding: 20px;
    width: 36%;
    padding-bottom: 0px;
    padding-right: 0px;
    border-radius: 10px;
    height: 100%;
    display: flex;
  }
  .tabs-detail-container{
    display: flex;
    flex-direction: column;
  }
  
  .tab {
    border: none;
    color: white;
    padding: 9px 18px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #232425;
    font-weight: 600;
    font-size: 12px;
  }
  

  .active-tab-item{
    background-color: #404a79ae;
  }


  .pie-chart-container{
    padding: 0px;
  }

  /* Add this CSS to your stylesheet */
.pie-chart-wrapper .recharts-default-legend {
  font-size: 12px; /* Adjust the font size as needed */
}