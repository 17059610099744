.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50; /* Green background */
    color: white; /* White text */
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it appears above other elements */
    transition: opacity 0.3s ease-in-out;
  }
  
  .notification.fade-out {
    opacity: 0;
  }