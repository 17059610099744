.avatar-container {
    padding: 10px 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #0d0c0c;
    border-radius: 8px;
    color: #b0bec5;
    font-family: Arial, sans-serif;
}

.avatar-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  

  .identity-heading{
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
    color: aliceblue;
  }

  .label-peroson-element{
    font-size: 14px;
  }

  .avatar-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .avatar-heading {
    font-size: 12px;
    color: #fff;
  }

  .upload-button-image {
    display: block;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    text-align: center;
    background-color: transparent;
    border: 1px solid #90a4ae;
    margin-bottom: 10px;
  }
  
  .upload-button-image:hover {
    background-color: #1f201f;
  }
  
  .avatar-image{
    color: #0d0c0c;
    font-size: 18px;
    height: 14px;
    width: 16px;
    background-color: #ffffff;
    border-radius: 2px;
    margin-right: 5px;
  }
  .avatar-options {
    display: flex;
    justify-content: space-between;
  }
  
  .avatar-option {
    width: 100px;
    height: 100px;
    padding: 8px;
    background-color: #333;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .avatar-option:hover {
    transform: scale(1.1);
  }


  .behaviour-heading{
    font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
        color: aliceblue;
  }




  .settings-container {
    padding: 16px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #212121;
    border-radius: 8px;
    color: #b0bec5;
    font-family: Arial, sans-serif;
  }
  
  .section {
    margin-bottom: 24px;
  }
  
  .section-agent-heading {
    font-size: 14px;
    color: #fff;
    margin-bottom: 8px;
  }
  
  .section-description {
    font-size: 14px;
    margin-bottom: 12px;
  }
  
  .greeting-agent-input {
    width: 96%;
    padding: 12px;
    font-size: 16px;
    color: #b0bec5;
    background-color: #0a0a0a;
    border: none;
    border-radius: 4px;
    margin-bottom: 8px;
    resize: none;
  }
  
  .character-count {
    font-size: 12px;
    color: #b0bec5;
  }
  
  .behavior-option {
    padding: 12px;
    background-color: #0a0a0a;
    border-radius: 8px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .behavior-option:hover {
    background-color: #444;
  }
  
  .option-heading {
    font-size: 14px;
    color: #64b5f6;
    margin-bottom: 4px;
  }
  
  .option-description {
    font-size: 13px;
    color: #b0bec5;
  }

  .section-heading{
    font-size: 20px;
  }

  .section-peroson-body {
    margin-top: 0px;
    background-color: #171717;
    padding: 10px;
    border-radius: 10px;
  }
