.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  background-color: #03070b;
  color: #ffffff;
  
}
.main-body-container {
  display: flex;
  min-height: 100%;
  margin-bottom: 15px;
  overflow-y: auto;
  width:100%;
}