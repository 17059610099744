.meeting-tabs-container{
  border:2px solid white;
  border-radius:8px;
  width:100%;
  margin-top:20px;
}
.meeting-tabs{
  width:100%;
  border-bottom:2px solid white;
}
.meeting-tab-button{
  padding:10px;
}
.meeting-tab-button.active{
  color:white;
  border-bottom:3px solid blue;
}
.join-button{
  background-color: "transparent";
  border:2px solid white;
  border-radius:8px;
  padding:8px;
}
.join-button-new{
  width: 80px;
  height:40px;
  font-size:15px;
  background-color: #212328;
  border: 0.5px solid #363638;
  border-radius: 10px;
  color: #b0b0b0;
  font-weight: 600;
}