.modal--assistant-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-assistant {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 800px;
  max-width: 90%;
  color: #b0b0b0;
  height:  75%;
  overflow: auto;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-assistant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #363638;
}

.main-heading{
  color: aliceblue;
}



.modal-assistant-body {
  margin-top: 10px;
  width: 100%;
}

.chosse-a-template-container{
  border-bottom: 1px solid #363638;
  margin-bottom: 10px;

}

.choose-heading{
  font-size: 17px;
  color: aliceblue;
  font-weight: 600;
  margin-top: 0px;
}

.choose-para{
  font-size: 14px;
}

.label-assistant-name {
  display: block;
  font-size: 13px;
  color: aliceblue;
  margin-bottom: 5px;
  
}

.span-element{
  color: #c09e17;
  font-size: 14px;
}

.input-create-assistant-model {
  width: 96%;
  height: 22px;
  font-size: 12px;
  padding: 10px;
  color: aliceblue;
  border-radius: 5px;
  border: 1px solid #363638;
  background-color: #131314;
  outline: none;
}

.template-options {
  margin-top: 0px;
}

.template-option {
  background: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #363638;
  margin: 10px;
  cursor: pointer;
}


.options-heading{
  font-size: 15px;
  color: aliceblue;
  font-weight: 500;
  margin-top: 0px;
}

.options-para{
  margin-bottom: 0px;
  font-size: 13px;
}

.quickstart-option {
  background: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #363638;
}


.quick-staet-heading{
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 16px;
}


.quickstart-options {
  border: 1px solid #363638;
  background-color: #131314;
  padding: 20px;
  padding-top: 15px;
  margin-top: 20px;
  border-radius: 10px;
}

.detail-quick-start-container{
  display: flex;
  flex-direction: column;
}

.quickstart-option h4 {
  margin: 0;
}

.quickstart-option{
  cursor: pointer;
  width: 220px;
  height: 120px;
  overflow: auto;
  margin-right: 0px;
  margin-bottom: 20px;
}

.button-container{
  text-align: right;
  margin-top: 20px;
}

.create-ass-button{
  height: 32px;
  width: 120px;
  font-size: 12px;
  font-weight: 600;
  background-color: #23afad;
  border: none;
  border-radius: 8px;
  color: aliceblue;
}

.active-assistant{
  background-color: #23afad35;
}

.detail-q-s-container{
  display: flex;
  justify-content: space-between;
}


.cancel-button{
  text-align: right;
  background-color: transparent;
  border: none;
}