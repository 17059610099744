

body{
    margin:0;
    padding:0;
    background-color: black;
}

.about-container {
    background-color: black;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
}

.about-paragraph {
    text-align: justify;
}

.about-header, .about-bio, .about-aitota, .about-footer {
    margin-bottom: 20px;
    padding:10px;
}

.about-heading1, .about-heading2 {
    font-weight: bold;
    color: #fff;
}

.about-heading1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.about-heading2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.about-paragraph {
    margin-bottom: 10px;
}

.about-link {
    color: #fff;
    text-decoration: underline;
}

.about-link:hover {
    color: #ccc;
}

.about-footer-paragraph {
    text-align: center;
    margin-top: 20px;
}

.about-founder-image {
    height: 250px;
    width: 250px;
    margin: auto;
}

@media (max-width: 768px) {

    .about-heading1 {
        font-size: 1.8em;
    }

    .about-heading2 {
        font-size: 1.3em;
    }

    .about-paragraph {
        font-size: 1em;
    }
}


@media (min-width:768px){
    .about-container{
        width:60%;
    }
}
/* .about-section{
    font-family: "Roboto";
    color: white;
    padding:40px;
}
.about-main-heading{
    color: white;
    text-align: center;
    font-weight: bold;
}
.founder-img1{
    height:200px;
    object-fit: contain;
    margin:auto;
}
.founder-img2{
    height:200px;
    object-fit: contain;
}
.founder-img-container{
    text-align: center;
}


.founder-description{
    text-align: justify;
    font-size:20px;
    color:white;
}


.founder-details{
    margin-top:10px;
    margin-bottom:10px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Roboto";
    color:white;
}
@media (min-width:768px){
    .founder-img1{
        order:1;
        width:30%;
        flex-grow: 1;
    }
    .founder-img2{
        height:300px;
    }
    .founder-img-container{
        order:1;
    }
    .about-container1{
        display: flex;
        justify-content: space-around;
        margin-top:20px;
        margin-bottom:20px;
        padding:20px;
    }
    .founder-description{
        width:70%;
        line-height: 2;
        color:white;
    }
} */