.sidebar {
    width: 18vw;
    color: #b0b0b0;
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
  }
  
  .side-bar-logo {
    padding: 20px;
    text-align: center;
  }
  
  .logo-img {
    width: 100px;
  }
  
  .sidebar-menu {
    list-style-type: none;
    padding: 0;
  }

  .side-item{
    font-size: 14px;
  }
  
  .sidebar-item,
  .sidebar-subitem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .sidebar-item:hover {
    background-color: #15161a;
    border: 0.5px solid #363638;
    border-radius: 8px;
    color: #ffffff;
  }
  
  .sidebar-item span {
    flex-grow: 1;
  }
  
  .sidebar-submenu {
    list-style-type: none;
    padding: 0;
    padding-left: 20px;
  }
  
  .sidebar-profile {
    margin-top: auto;
    padding: 10px;
  }


  .sidebar-email{ 
    margin-top: auto;
    padding: 0px;

  }
  
  .sidebar-email {
    padding-top: 5px;
    font-size: 14px;
    color: #8e8e8e;
  }
  
  .sidebar-item span,
  .sidebar-subitem span {
    margin-left: 10px;
  }
  
  .profile-sidebar{
    margin-bottom: 10px;
    display: flex;
    padding: 8px 5px;
    align-items: center;
    border-radius: 8px;
  }

  .profile-sidebar:hover {
    background-color: #15161a;
    border: 0.5px solid #363638;
    border-radius: 8px;
    
  }


  .profile-icon-image{
    font-size: 15px;
    
    padding: 5px;
    border-radius: 5px;
  }

  .span-profile{
    margin-left: 10px;
  }

  .active{
    background-color: #2f3756;
    border: 0.5px solid #363638;
    border-radius: 8px;
    color: #010310;
    font-weight: 600;
  }

  .hover:hover{
    background-color: #2f3756;
    border: 0.5px solid #363638;
    color: #010310;
  }

  .nav-link {
    text-decoration: none;
    color: #b0b0b0;
  }