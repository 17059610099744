.pop-up-contianer {
    background-color: #0d0c0c;
    padding: 20px;
    padding-top: 0px;
    border-radius: 10px;
    overflow: auto;
    margin-top: 0px;
    width: 95%;
  }
  
  .voices-container {
    padding: 24px;
    border-radius: 12px;
    width: 90%;
    color: #fff;
    text-align: center;
    height: 350px;
    overflow: auto;
  }
  
  
  .title {
    margin-top: 0px;
    font-size: 20px;
    margin-bottom: 24px;
  }
  
  .voice-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .voice-item {
    display: flex;
    align-items: center;
    background-color: #1e1e1e;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 8px;
    transition: background-color 0.3s;
    width: 98%;
  }
  
  .voice-item.selected {
    background-color: #38383b;
  }
  
  .flag-icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 15px;
  }
  
  .voice-info {
    flex-grow: 1;
    text-align: left;
  }
  
  .voice-name {
    font-size: 16px;
    margin-bottom: 4px;
  }
  
  .voice-meta {
    font-size: 14px;
    color: #b0bec5;
  }
  
  .play-button,
  .use-voice-button {
    background-color: transparent;
    border: none;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 8px;
  }
  
  .use-voice-button {
    background-color: #333;
  }