.assistant-chat{
  display: flex;
  align-items: center;
  justify-content: start;
}
.user-chat{
  display: flex;
  align-items: center;
  justify-content: end;
}
.assistant-flexi{
  display: flex;
  align-items: center;
}