.template-form-container{
    padding:20px;
    flex-grow: 1;
    background-color: #15161a;
    border-radius: 20px;
    border: 0.5px solid #363638;
    height:100%;
    overflow-y: scroll;
    overflow-x:hidden;
}

.template-form-card{
    width:100%;
    background-color: #0d0c0c;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    margin:10px;
    padding-top:20px;
    padding-bottom:10px;
    padding-left:10px;
    padding-right:20px;
}
.tab-section-heading{
    font-weight: bold;
    font-size: 20px;
    margin-top:10px;
    margin-bottom:10px;
}

.template-form-input{
        width:50%;
        padding: 10px;
        border-radius: 5px;
        border: 0.5px solid #363638;
        color: white;
        background-color: #0d0c0c;
        outline: none;
      } 

.template-form-section-heading{
    font-weight: bold;
    color:white;
    font-size:25px;
}
.template-form-save-button{
    background-color: #2f3756;
    border-radius: 10px;
    height: 40px;
    padding: 0px 10px;
    text-align: center;
}