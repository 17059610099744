
  
  .main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #15161a;
    border-radius: 20px;
    margin-right: 15px;
    border: 0.5px solid #363638;
  }

  .content-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top:20px;
    padding-bottom:10px;
  }
  
  .no-data {
    text-align: center;
  }

  .no-data-icon-container{
      
  }
  
  .no-data-icon {
    width: 80px;
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .no-data-text {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .no-data-subtext {
    font-size: 14px;
    color: #888888;
  }