.sidebar-main-container {
  height: 100vh; /* Full height of the viewport */
  font-family: "Roboto";
  background-color: lightgreen;
  width: 20vw; /* 20% of the viewport width */
  display: flex;
}

.icon-bar {
    background-color: black;
    width: 100px;
    height: 100%;
}

.iconic-container {
    height:92%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.iconic-sidebar-icon {
    height: 26px;
    width: 26px;
    margin:10px;
    cursor:pointer;
}

.iconic-sidebar-icon:hover{
    height:30px;
    width:30px;
}
.description-bar{
    background-color: black;
    border:1px solid white;
    border-top:0;
    border-bottom:0;
    width:100%;
}
.description-heading{
    font-weight:bold;
    font-size:30px;
}
.icon-sidebar-img-logo{
    height:50px;
    width:50px;
    margin:auto;
}
.icon-bar-img-container{
    margin-top:10px;
    padding-bottom:15px;
}
.description-main-container{
    text-align: center;
    margin-top:10px;
    border-bottom:1px solid white;
}
.iconic-sidebar-item{
  padding:5px;
}
.iconic-sidebar-item.active{
    background-color: #2f3756;
    border: 0.5px solid #363638;
    border-radius: 100%;
    color: #010310;
    font-weight: 600;
  }
  .iconic-list-container{
    padding-left: 0;
  }
  .iconic-tabs-container{
    text-align: center;
    margin-top:10px;
  }
  .iconic-tab-item{
    cursor:pointer;
    display: flex;
      align-items: center;
      background-color: #2f3756;
      margin-top:5px;
      margin-bottom:5px;
      border-radius: 8px;
      height: 40px;
      margin-left:10px;
      margin-right:10px;
      padding: 0px 10px;
      text-align: center;
  }
  .iconic-tab-item.active {
    background-color: #4b5a89;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-weight: bold;
  }
  .iconic-link{
    color:white;
    text-decoration: none;
  }
  .mask-icon{
    display:none;
  }