.filters-container{
    background-color: #444;
    color: #fff;
    border: none;
    padding:20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top:20px;
    align-self:flex-start;
    width:100%;
    display: flex;
    justify-content: space-between;
}
.business-container{
    flex-grow: 1;
    padding:40px;
    background-color: #15161a;
    border-radius: 20px;
    margin-right: 15px;
    border: 0.5px solid #363638;
}
/* .business-container{
    background-color: #15161a;
    border: 0.5px solid #363638;
    width:90%;
    margin-right: 30px;
    border-radius:8px;
    padding:30px;
} */

.filter-item-container{
    width:30%;
}

.filter-input{
    background-color: black;
    border: none;
    margin-top: 10px;
    height: 40px;
    padding:20px;
    border: 0.5px solid #363638;
    border-radius: 6px;
    color: #ffffff;
    outline: none;
    width:100%;
}
.addnew-button{
    width: 130px;
    height: 40px;
    font-size:15px;
    background-color: #212328;
    border: 0.5px solid #363638;
    border-radius: 10px;
    color: #b0b0b0;
    font-weight: 600;
  }

  .businesses-outer-container2 {
    overflow-y: auto;
    height: 300px;
  }
  .businesses-list-outer-container{
    overflow-y: auto;
    height:400px;
  }
  
  .businesseslist-container {
    background-color: #444;
    border: 0.5px solid #363638;
    padding: 20px;
    border-radius: 8px;
    margin: 0; /* Remove default margin */
    list-style: none; /* Remove default list styling */
  }
  
  .businesslist-item {
    background-color: #15161a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px; /* Add padding for better spacing */
    margin-bottom: 10px; /* Add space between list items */
    border-radius: 4px; /* Optional: Add border-radius for rounded corners */
    color:grey;
  }
  .businesslist-itemheading{
    font-weight: bold;
    font-size: 25px;
    margin:0;
  }
  .joiningdate{
    margin:0;
    font-size: 13px;
  }
  .flexi{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
.business-icon{
    margin-right: 10px;
}
.hidden-scrollbar::-webkit-scrollbar {
    width: 0px; /* Hide scrollbar width */
    background: transparent; /* Optional: for a transparent background */
  }
  
  /* For Firefox */
  .hidden-scrollbar {
    scrollbar-width: none; /* Hide scrollbar */
  }
  
  /* For IE and Edge */
  .hidden-scrollbar {
    -ms-overflow-style: none; /* Hide scrollbar */
  }