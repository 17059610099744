/* .blogs-main-container{
    min-height: 100vh;
    font-family: "Roboto";
    color:white;
    width:100%;
    padding:40px;
}
.blog-container-main-heading{
    font-weight:bold;
    font-size:36px;
}
.blog-main-image{
    height:70px;
    width:70px;
    margin-right:20px;
}
.blog-main-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.blog-card-container{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.blog-card{
    background-color: white;
    border-radius: 8px;
    color:black;
    font-family: "Roboto";
    margin:10px;
}
@media (min-width:768px){
    .blog-card{
        display: flex;
    }
}
.blog-card-content{
    padding:10px;
}
.blog-card-heading{
    font-weight: bold;
    font-size:25px;
}
.blog-card-image{
    height:250px;
    width:250px;
    border-top-left-radius: 8px;
    border-bottom-left-radius:8px;
}
@media (max-width:768px){
    .blog-card-image{
        width:100%;
    }
} */
.blogs-main-container {
    min-height: 100vh;
    font-family: "Roboto";
    color: white;
    width: 100%;
    padding: 40px;
}

.blog-container-main-heading {
    font-weight: bold;
    font-size: 36px;
}

.blog-main-image {
    height: 70px;
    width: 70px;
    margin-right: 20px;
}
@media (max-width:767px){
    .blog-container-main-heading{
        font-size:25px;
    }
    .blog-main-image{
        height:40px;
        width:40px;
    }
}

.blog-main-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.blog-card {
    background-color: white;
    border-radius: 8px;
    color: black;
    font-family: "Roboto";
    margin: 10px;
    width: 100%; /* Default to full width for small devices */
    max-width: 350px; /* Maximum width for large devices */
    box-sizing: border-box;
}

@media (min-width: 768px) {
    .blog-card {
        display: flex;
        max-width: 500px; /* Set a fixed width for larger screens */
    }
}

.blog-card-content {
    padding: 10px;
    flex-grow: 1;
}

.blog-card-heading {
    font-weight: bold;
    font-size: 25px;
}

.blog-card-image {
    height: 250px;
    width: 250px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    object-fit: cover;
}

@media (max-width: 767px) {
    .blog-card-image {
        width: 100%;
        border-radius: 8px 8px 0 0; /* Adjust border radius for small screens */
    }
}

.blog-link{
    text-decoration: none;
}