.disable-text{
    color:gray;
}
.available-template{
    background-color: #4CAF50;
    font-size:15px;
    border-radius:8px;
    padding:5px;
    color:white;
}
.active-button {
    background-color: #1E88E5; /* Blue for active state */
    color: white;
    border: none;
  }
  
  .inactive-button {
    background-color: #4CAF50; /* Default Green */
    color: white;
    border: none;
  }
  
  button:hover {
    opacity: 0.8; /* Hover effect */
  }
  .av-temp-cont{
    gap:5px;
    display: flex;
  }
/*   
.available-template .active{
    background-color: #1E88E5;
    font-weight:bold;
} */