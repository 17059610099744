.select-assistant-heading{
    font-size:20px;
    color:white;
    font-weight: bold;
    margin-top:10px;
}
.choose-assistant{
    margin-bottom:10px
}
.assistant-test{
    display: flex;
}
.sel-assistant{
    font-weight: bold;
    font-size:20px;
}

/* Keyframes for the pulsing effect */
@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Apply this to the mic icon when listening */
  .listening {
    animation: pulse 1s infinite;
  }
  
  /* Smooth transition between icons */
  .mic-container {
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .mic-container:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

.flex-cont{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assist-heading{
  font-weight:bold;
  font-size:16px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 1;
  }
  70% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.pulsing-ring {
  position: relative;
  width: 120px;
  height: 120px;
  background-color: #1f2937; /* Dark background */
  border-radius: 50%;
  animation: pulse 1.5s infinite;
  margin:auto;
}

.pulsing-ring:before,
.pulsing-ring:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1f2937; /* Dark background */
  border-radius: 50%;
  animation: pulse 1.5s infinite;
  z-index: -1;
}

.pulsing-ring:before {
  animation-delay: 0.5s;
}

.pulsing-ring:after {
  animation-delay: 1s;
}